.course_section1{
    background-color: rgb(71, 77, 105);
    padding: 20px;
    color: white;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 5px 6px 5px red;
}
.course_section2{
    background-color: aqua;
    padding: 20px;
    color: black;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 5px 6px 5px rgb(160, 4, 90);
}
.course_section3{
    background-color: rgb(55, 7, 79);
    padding: 20px;
    color: rgb(233, 223, 223);
    border-radius: 10px;
    margin: 10px;
    box-shadow: 5px 6px 5px rgb(213, 231, 46);
}
.course_section4{
    background-color: rgb(187, 16, 79);
    padding: 20px;
    color: rgb(242, 241, 241);
    border-radius: 10px;
    margin: 10px;
    box-shadow: 5px 6px 5px rgb(24, 213, 226);
}
.contact_Heading{
    font-size: 16px;
    font-weight: 800;
    color: rgb(12, 123, 192);
}
.Contact_form input, textarea{
    width: 100%;
    margin-bottom: 10px;
    padding: 6px;
    border: 1px solid rgb(19,19,19);
    box-shadow: 5px 5px 10px #0c7bc0;
    border-radius: 5px;
}
.Submit_btn{
    background: rgb(195,96,34);
    background: linear-gradient(0deg, rgba(195,96,34,1) 21%, rgba(211,71,249,1) 81%);
    border: none;
    color: #fff;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 10px;
    text-transform: uppercase;
    width: 100px;
    cursor: pointer;
    box-shadow: 10px 10px 10px rgb(228, 131, 131) ;
    animation-duration: 2.5s;
    border-radius: 20px;
}
.kol_md{
    padding-left: 30px;
}
.kol_md h2{
    color: #0c7bc0;
    text-shadow: 5px 6px 7px #0c7bc0;
}
.kol_md p{
    text-shadow: 5px 5px 5px gray;
}
.login_style input{
   border: none;
   outline: none;
   background-color: transparent;
   color: white;
}
.input_div{
    border: 1px solid white;
    width: 90%;
    padding: 7px;
    border-radius: 20px;
    margin-top: 15px;
    color: white;
}
.klkl_2{
    text-shadow: 5px 5px 5px gray;
}

.login_1{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(8,8,91,1) 12%, rgba(9,135,161,1) 71%);
    border-radius: 20px;
    padding: 20px;
    color: white;
    box-shadow: 15px 15px 15px gray;
}
.login_1 h3{
    display: flex;
    justify-content: center;
    color: white;
}
.signup_links a{
    color: white;
    justify-content: space-between;
}
.signup_links{
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding: 8px;
}
.Submit_btn1{
    background: rgb(80, 195, 34);
    background: linear-gradient(0deg, rgb(34, 72, 195) 21%, rgba(211,71,249,1) 81%);
    border: none;
    color: #fff;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 10px;
    text-transform: uppercase;
    width: 100px;
    cursor: pointer;
    box-shadow: 10px 10px 10px rgb(228, 131, 131) ;
    animation-duration: 2.5s;
    border-radius: 20px;
}
